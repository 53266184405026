<template>
  <div class="top-section">
    <img src="@/assets/img/logo3.png" alt="Logo" class="logo">

    <el-button v-if="!isLog" style="height: 30px;"  type="primary" @click="routeToLogin">登录</el-button>
    <i v-if="isLog" class="el-icon-user-solid user-icon" @click="changePartInfo"></i>

    <!-- Your content goes here -->
  </div>
</template>

<script>
export default {
  name: 'topDiv',
  created() {
    this.isUserLoggedIn();
    // console.log(this.isLog);
  },
  data(){
    return {
      // showMyPartInfo: false,
      // isLog:false,
      temp: false,
    }
  },
  computed: {
    isLog() {
      return this.$store.state.isLog;
    },
  },

  methods:{ //安全性有待商榷，后端应当进行判断
    isUserLoggedIn() {
      const accessToken = localStorage.getItem('accessToken');
      const expireTime = localStorage.getItem('expireTime');
      const currentTime = new Date().getTime();
      if (accessToken && (!expireTime || parseInt(expireTime, 10) > currentTime)){
        this.$store.commit('SET_LOGIN_STATE', true);
      }else {
        this.$store.commit('SET_LOGIN_STATE', false);
      }
    },
    routeToLogin() {
      this.$router.push('/login');
    },
    changePartInfo() {
      this.temp =  this.$store.state.showMyPartInfo;
      if(this.temp){
        this.$store.commit('SET_PART_INFO_STATE', false);
      }else{
        this.$store.commit('SET_PART_INFO_STATE', true);
      }
      console.log(this.$store.state.showMyPartInfo);
      // return this.temp;
    },

  }

};
</script>

<style scoped>
.top-section {
  height: 0vh;
  background-color: #114463;
  color: #ffffff;
  padding: 20px;
  margin: 0; /* 添加这行，重置外边距为0 */
  width: 100vw;
  box-sizing: border-box;
  position: relative;
  display: flex; /* 启用Flexbox布局 */
  align-items: center; /* 垂直居中 */
  justify-content: flex-end; /* 水平右对齐 */
}

.logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 20%;
  max-height: 90%;
  margin-left: 20px;
}

.user-icon {
  display: inline-block;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
}
</style>
